/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Snackbar } from '@rmwc/snackbar';
import React from 'react';

import styles from './AlertSentNotification.module.scss';

export const SNACKBAR_MESSAGE = 'Alert Sent!';

interface Props {
  showAlertSentNotification: boolean;
  setShowAlertSentNotification: (value: boolean) => void;
}

const ForceRunNotification: React.FC<React.PropsWithChildren<Props>> = ({
  showAlertSentNotification,
  setShowAlertSentNotification,
}) => (
  <Snackbar
    className={styles.AlertSentNotification}
    open={showAlertSentNotification}
    onClose={() => setShowAlertSentNotification(false)}
    message={SNACKBAR_MESSAGE}
    icon={{ icon: 'check_circle', size: 'medium' }}
    timeout={2000}
  />
);

export default ForceRunNotification;
